
import Vue from 'vue'
import * as rules from 'vee-validate/dist/rules'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'

localize({
  en: {
    messages: {
      required: field => {
        return `The ${field} field is required`
      },

      required_if: field => {
        return `The ${field} field is required`
      },

      email: field => {
        return `The ${field} must be a valid email address`
      },

      double: field => {
        return `The ${field} must be a valid number`
      },

      length: (field, option) => {
        if (option.length <= 1) {
          return `The ${field} must have ${option.length} character`
        }
        return `The ${field} must have ${option.length} characters`
      },

      between: (field, option) => {
        return `The ${field} must be between ${option.min} and ${option.max}`
      },

      max: (field, option) => {
        if (option.length <= 1) {
          return `The ${field} must be atleast lesser than or equal to ${option.length} character`
        }
        return `The ${field} must be atleast lesser than or equal to ${option.length} characters`
      },

      min: (field, option) => {
        if (option.length <= 1) {
          return `The ${field} must be atleast greater than or equal to ${option.length} character`
        }
        return `The ${field} must be atleast greater than or equal to ${option.length} characters`
      },

      max_value: (field, option) => {
        return `The ${field} must be atleast lesser than or equal to ${option.max}`
      },

      min_value: (field, option) => {
        return `The ${field} must be atleast greater than or equal to ${option.min}`
      },

      confirmed: (field, option) => {
        return `The ${field} must be the same as the value of ${option.target}`
      }
    }
  }
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('currency', {
  validate (value, { min }) {
    const transformToNumericValue = parseFloat(value.length ? value.replace(/,/g, '') : value)
    return !isNaN(transformToNumericValue) && transformToNumericValue >= min
  },
  params: ['min'],
  message: 'Please enter a valid currency amount and must be greater than {min}.'
})

extend('not_equal', {
  validate: (field, { target }) => field !== target,
  params: [{ name: 'target', isTarget: true }],
  message: (field, { target }) => `The ${field} must not be the same as the value of ${target}.`
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
