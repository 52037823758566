function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/lo/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RLogisticsOfficer = [
  {
    path: '/logistics-officer/dashboard',
    component: view('Dashboard'),
    name: 'lo.dashboard',
    authorizedRole: 'lo'
  },
  {
    path: '/logistics-officer/deliveries/scheduling/:scheduler?',
    component: view('scheduling/Base'),
    name: 'lo.deliveries.scheduling',
    authorizedRole: 'lo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Deliveries'
        },
        {
          text: 'Scheduling',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/deliveries/dispatching/:dispatcher?',
    component: view('dispatching/Base'),
    name: 'lo.deliveries.dispatching',
    authorizedRole: 'lo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Deliveries'
        },
        {
          text: 'Dispatching',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/deliveries/receiving/:receiver?',
    component: view('receiving/Base'),
    name: 'lo.deliveries.receiving',
    authorizedRole: 'lo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Deliveries'
        },
        {
          text: 'Receiving',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/deliveries/closing/:closer?',
    component: view('closing/Base'),
    name: 'lo.deliveries.closing',
    authorizedRole: 'lo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Closing',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicles',
    component: view('maintenances/Vehicles'),
    name: 'lo.maintenances.vehicles',
    authorizedRole: 'lo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Vehicles',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/drivers',
    component: view('maintenances/Drivers'),
    name: 'lo.maintenances.drivers',
    authorizedRole: 'lo',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Drivers',
          active: true
        }
      ]
    }
  }
]

export default RLogisticsOfficer
